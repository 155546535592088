import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

// icons
import { PiExport } from "react-icons/pi";
import { TfiExport } from "react-icons/tfi";
import { FaChildren, FaPerson } from "react-icons/fa6";
import { FaShieldAlt, FaDog } from "react-icons/fa";
import { TbHomeDown, TbHomeMove } from "react-icons/tb";
import { IoIosContact } from "react-icons/io";

import logo from "../logo/logo.png";

import { useGridApiContext } from '@mui/x-data-grid';



// Import the json data for language translation

import translateData from '../language/de.json'



//  material theme
import { useTheme } from '@mui/material/styles';
import CalendarComponent from './CalendarComponent';




// const columnTranslations = {
//   'Booking_No': 'Broj rezervacije',
//   'Name': 'Ime',
//   'Arrival': 'Dolazak',
//   'Departure': 'Odlazak',
//   'Adult': 'Odrasli',
//   'Children': 'Djeca',
//   'Pets': 'Ljubimci',
//   'Insurance': 'Osiguranje',
// };

const columnsPdf = [
  { field: 'Booking_No' },
  { field: 'Name' },
  { field: 'Arrival' },
  { field: 'Departure' },
  { field: 'Adult' },
  { field: 'Children' },
  { field: 'Pets' },
  { field: 'Insurance' },
];

// Translate the columns for the PDF
const translatedColumns = columnsPdf.map(col => ({
  ...col,
  // if it's for english, keep it only on col.field
  // header: col.field,
  header: translateData.columnTranslations[col.field] || col.field, // Fallback to the original field name if no translation is found
  dataKey: col.field,
}));


// Use `translatedColumns` where you need the Croatian headers


function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
}


// Function that changes the name "Eigene Buchung" to "Vlastiti booking"
// or "Own booking" if english
// Offline for german language
const translateBookingName = (value) => {
  if (value === "Eigene Buchung") {
    // return "Own booking";
    return translateData.ownBooking;
  }
  return value;
};


const exportPdf = (bookings, propertyToSave) => {
  const doc = new jsPDF();

  if (propertyToSave in bookings) {
    const bookingsArray = bookings[propertyToSave];

    if (bookingsArray.length === 0) {
      return; // No bookings for the selected property, do nothing
    }

    const columnNames = columnsPdf.map(colDef => colDef.field);
    const rows = bookingsArray.map(booking =>
      columnsPdf.reduce((acc, colDef) => {
        // Apply translation for the "Name" field
        // offline for german language
        if (colDef.field === 'Name') {
          acc[colDef.field] = translateBookingName(booking[colDef.field]);
        }
        // // Apply date formatting for "Arrival" and "Departure" fields
        else if (colDef.field === 'Arrival' || colDef.field === 'Departure') {
          acc[colDef.field] = formatDate(booking[colDef.field]); // Assuming formatDate is a function you've defined to format your dates
        }
        // Leave other fields as is
        else {
          acc[colDef.field] = booking[colDef.field];
        }
        return acc;
      }, {})
    );


    // doc.autoTable(columnNames, rows);
    
    
    doc.autoTable({
      // columns: columnNames.map(name => ({header: name, dataKey: name})),
      columns: translatedColumns,
      body: rows,
      theme: 'striped', // Predefined themes: 'striped', 'grid', 'plain', 'css'
      startY: 20, // Initial y position, defaults to 10 (or last table's end position)
    });
    

    doc.text(propertyToSave, 16, 13); // Add the property name as the section header

    // const insuranceMessage =  `Osiguranje:`+ `\n`+ `Y - Nema depozita pri dolasku, prijava štete agenciji`+`\n`+ `N - Depozit se placa pri dolasku`;
    // const insuranceMessage =  `Insurance:`+ `\n`+ `Y - No deposit on arrival, report damage`+`\n`+ `N - Deposit to be paid on arrival`;
    // const insuranceMessage = `${translateData.insurance.title}: \n Y - ${translateData.insurance.positive} \nN - ${translateData.insurance.negative}`;
    const insuranceMessage = `${translateData.insurance.title}:\nY - ${translateData.insurance.positive}\nN - ${translateData.insurance.negative}`;

    // const insuranceMessage =  `Osiguranje:`+ `\n`+ `Y - Nema depozita pri dolasku, prijava štete agenciji`+`\n`+ `N - Depozit se placa pri dolasku`;

    // Calculate position for the insurance message
    let finalY = doc.lastAutoTable.finalY || 10; // Use the Y position where the last table ended, or default to 10
    finalY += 10; // Add some extra space


    doc.setFontSize(10);


    // Add the insurance message text inside the rectangle
    doc.setTextColor(0, 0, 0); // Set text color to black (or any desired color)

    doc.text(insuranceMessage, 15, finalY + 6); // Position the message 10 units below the table



    const companyName = `© 22 Estates GmbH 2015 - ${new Date().getFullYear()}`;
    
    
    // Add company name in the footer

    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextDimensions(companyName).w;
      const textHeight = doc.getTextDimensions(companyName).h;
      const xPos = (pageWidth - textWidth) / 2;
      const yPos = doc.internal.pageSize.height - 10; // 10 units from the bottom
      doc.text(companyName, xPos, yPos);

      doc.addImage(logo, 'PNG', xPos+7, doc.internal.pageSize.height - 35, 40, 20);
    }

    doc.save(`${propertyToSave}-bookings-export.pdf`);
  }
};




// Sample legend items
// const legendItems = [
//   { label: 'No deposit upon arrival, report damage', color: 'rgba(0, 255, 0, 1)' },
//   // Nema depozita pri dolasku, prijava štete agenciji, Keine Kaution bei Ankunft, Schäden melden
//   { label: 'Deposit to be paid on arrival', color: 'rgba(255, 0, 0, 1)' },
//   // Depozit se plaća pri dolasku, Kaution bei Ankunft zu zahlen
// ];
const legendItems = [
  { label: `${translateData.insurance.positive}`, color: 'rgba(0, 255, 0, .7)' },
  { label: `${translateData.insurance.negative}`, color: 'rgba(255, 0, 0, .7)' },
]

const Legend = () => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', gap: 2, p: 1, border: '1px solid grey', borderRadius: 3, marginBottom: 10,   }}>
  <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      flexDirection: { xs: 'column', sm: 'row' },
      '& svg': { // Targeting the svg element directly for styling
        fontSize: { xs: '20px', sm: '30px' }, // Adjust icon size based on screen size
      }
    }}>
      <FaShieldAlt />
      <Typography> {translateData.insurance.title}</Typography>
      {/* Osiguranje, Versicherung */}
    </Box>
    {legendItems.map((item) => (
      
      <Box key={item.label} sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on xs screens, horizontally on sm and above
            gap: 1 }}>
        <Box sx={{ 
          width: 20, 
          height: 20, 
          flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on xs screens, horizontally on sm and above

          backgroundColor: item.color }} />
        <Typography>{item.label}</Typography>
      </Box>
    ))}
  </Box>
);


const getInsuranceStatusStyle = (status) => {
  return status === 'Y' ? { color: 'green' } : { color: 'red' };
};

// Also test, DELETE if not needed





// returns objects for the toolbar
// the default toolbar has a density selector which is not needed
function CustomToolbarFactory(data, exportFunction, propertyName) {
  
return function CustomToolbar(someData) {

  // Function to sort each property's array by the 'Arrival' field
  const sortDataByArrival = (dataArray) => {
    return dataArray.sort((a, b) => new Date(a.Arrival) - new Date(b.Arrival));
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      {/* The grid doesn't work well on phones */}
      {/* <GridToolbarFilterButton /> */}

      {/* Multiple export types */}
      {/* Uncomment if you wish to have an option to export as CSV */}
      {/* <GridToolbarExport /> */}

      {/* Export as PDF button */}
      
      <Button startIcon={<PiExport />} size='small-' color="primary"
         onClick={() => {
          // exportFunction(data, propertyName)
          const sortedData = {};

            // Iterate through each property in the data object
            for (const property in data) {
              if (data.hasOwnProperty(property)) {
                // Sort the array for the current property
                sortedData[property] = sortDataByArrival(data[property]);
              }
            }

            // Pass the sorted data object to the export function
            exportFunction(sortedData, propertyName);
        }
          }>
        {/* <PiExport /> */}
        Export PDF

    </Button>
    </GridToolbarContainer>
  );
}
}


const transformedData = [];

const isSmallScreen = window.innerWidth < 600; // Get the screen size and see if it's smaller than 600px. If it is, it is a phone



export default function PropertyTable({ bookings }) {
  
  // get the user window size, if it is smaller than 600px, it is a phone
  // it is used to change the table column size
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Use the useEffect hook to add an event listener that updates the screenWidth state variable when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Create a ref to the scroll container
  // It manipulates the horizontal bar of the container
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const maxScroll = container.scrollWidth - container.clientWidth;
    let direction = 1; // Start by scrolling to the right
    let position = 0;

    const step = () => {
      if (!container) return;

      // Update position and direction at the edges
      if (position >= maxScroll || position <= 0) {
        direction *= -1;
      }

      position += direction * 2; // Speed of scrolling
      container.scrollLeft = position;

      requestAnimationFrame(step);
    };

    // Start the animation loop
    const animationFrameId = requestAnimationFrame(step);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);


  const columns = [
    // { field: 'Booking_No', headerName: 'Booking No', width: 150 },

    // name width 120, arrival width 120, departure width 140
    // { field: 'Name', headerName: 'Name', sortable: false, width: 140},
    { 
      field: 'Name', 
      headerName: <span className="iconProperty"><IoIosContact /></span>, 
      sortable: false,
      // width: screenWidth < 600 ? 150 : 170
      flex: 1, 
      minWidth: 180
    },

    { field: 'Arrival', headerName: 'Arrival', sortable: false, hide: true},

    // TbHomeDown, TbHomeMove

    {
      field: 'formattedArrival', // Use the formatted date for display
      // headerName: 'Arrival',
      headerName: <span className="iconProperty"><TbHomeDown /></span>,
      sortable: false,
      // width: screenWidth < 600 ? 100 : 140,
      flex: 1,
      minWidth: 100,

      // Remove valueGetter since we're using a preformatted value
    },
    {
      field: 'formattedDeparture', // Use the formatted date for display
      // headerName: 'Departure',
      headerName: <span className="iconProperty"><TbHomeMove /></span>,
      sortable: false,
      // width: screenWidth < 600 ? 100 : 140,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      hide: true,
      
    },

    // { field: 'Departure', headerName: 'Departure', flex: 1, },

    // { field: 'Adult', headerName: 'Adult', width: 120, sortable: false },
    {
      field: `Adult`, 
      headerName: <span className="iconProperty" style={{marginLeft: "-5px"}}><FaPerson /></span>, 

      sortable: false,
      // width: screenWidth < 600 ? 60 : 80, 
      flex: 1,
      minWidth: 70
    },
    // { field: 'Children', headerName: 'Children', width: 140, sortable: false },
    { 
      field: 'Children', 
      headerName: <span className="iconProperty"><FaChildren /></span>, 
      sortable: false,
      // width: screenWidth < 600 ? 60 : 80, 
      flex: 1,
      minWidth: 70
     },

    // { field: 'Pets', headerName: 'Pets', width: 100, sortable: false },
    { 
      field: 'Pets', 
      headerName: <span className="iconProperty" style={{marginLeft: "-1px"}}><FaDog /></span>, 
      sortable: false,
      // width: screenWidth < 600 ? 80 : 100, 
      flex: 1,
      minWidth: 70,
     },

    // { field: 'Insurance', headerName: 'Insurance', width: 140, sortable: false },
    { 
      field: 'Insurance', 
      headerName: <span className="iconProperty"><FaShieldAlt /></span>, 
      sortable: false,
      headerAlign: 'center', // Center align the header text
      // width: screenWidth < 600 ? 140 : 140, 
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', // Center content horizontally
          alignItems: 'center', // Center content vertically
          width: '100%', 
          height: '100%', 
          // color: 'white',
          backgroundColor: params.value === 'Y' ? 'rgba(0, 255, 0, .7)' : 'rgba(255, 0, 0, .7)',
          }}>
          {params.value}
        </div>
      ),
     },
];

// Custom class name generator
function getClassName(params) {
  console.log('debug', params);
  // Return a class based on the status of the row
  return `status-${params.row.status}`;
}


  transformedData.length = 0;
  // console.log(bookings)
    for (const propertyName in bookings) {

        // Transform the object into an array that contains the property name
        // This is used to display the property name in the table
        // The id is the booking number
        // The name is the name of the booking person

        bookings[propertyName].forEach(item => {
          transformedData.push({ ...item, Property: propertyName, id: item.Booking_No });
        });
      }

      // new tests for scroll

  //     const gridRef = React.useRef(null);
  // const animationRef = React.useRef(null);
  // const startTimeRef = React.useRef(null);

  // const scrollGrid = (timestamp) => {
  //   if (!startTimeRef.current) startTimeRef.current = timestamp;
  //   const elapsed = timestamp - startTimeRef.current;

  //   if (elapsed < 3000) { // Duration of the animation in milliseconds (3 seconds)
  //     const element = gridRef.current.getElementsByClassName('MuiDataGrid-virtualScroller')[0];
  //     const progress = elapsed / 3000;
  //     element.scrollTop = progress * (element.scrollHeight - element.clientHeight);
  //     element.scrollLeft = progress * (element.scrollWidth - element.clientWidth);
  //     animationRef.current = requestAnimationFrame(scrollGrid);
  //   }
  // };

  // React.useEffect(() => {
  //   animationRef.current = requestAnimationFrame(scrollGrid);
  //   return () => cancelAnimationFrame(animationRef.current);
  // }, []);

      



 
        
    
    return (
        <Box >
           {/* {Object.entries(bookings).map(([propertyName, bookingsArray]) => ( */}
           {Object.entries(bookings).map(([propertyName, bookingsArray]) => {
            // this currently works, but you can't stop the animation
                    


            // dataGridContainer is the main container of the table and the title
            // it is used to style the title and the table  
            // the title is styled with a background color and a border radius
            // the table is styled with a padding
            // the title is the property name
            // the table is the bookings for the property
            // the table is a DataGrid from @mui/x-data-grid
            // the DataGrid is a table that has a toolbar
            // the toolbar is a custom toolbar that has a button to export the table as a PDF
            // the button is styled with a color and an icon
            
            return (
          <div ref={containerRef} key={propertyName} className='dataGridContainer' >
           
            <div style={{textAlign: "left"}}>
                <Typography variant="h6" sx={{ padding: 2, backgroundColor: "rgba(80, 80, 80, 0.8)", color: "white", borderRadius: "10px 10px 2px 2px" }}>
                    {propertyName}
                </Typography>

            </div>
              <DataGrid
              // ref={gridRef}
                rows={
                    bookingsArray.map(booking => ({ ...booking, 
                    Name: (booking.Name==="Eigene Buchung" ? translateData.ownBooking : booking.Name),
                    id: booking.Booking_No, 
                    Arrival: booking.Arrival,
                    formattedArrival: formatDate(booking.Arrival), // This property is used for display
                    Departure: booking.Departure,
                    formattedDeparture: formatDate(booking.Departure), // This property is used for display
                    status: booking.status,
                    // Arrival: formatDate(booking.Arrival),
                    // Departure: formatDate(booking.Departure)
                  }))}

                  // this was the old way to show columns
                columns={columns}
               
                getRowClassName={(params) => `status-${params.row.status}`}

                
                initialState={{
                  
                  columns: {
                    columnVisibilityModel: {
                      Arrival: false,
                      Departure: false,
                      status: false,
                    }
                  },
                
                }}


                // This is the part that imports the grid toolbar to the tables
                slots={{
                  toolbar: CustomToolbarFactory(bookings, exportPdf, propertyName),
                  
                }}
              
                sortModel={[
                  { field: 'Arrival', sort: 'asc' },
                ]}
                sx={{

                  // CSS to style the table
                  // It adds a border radius to the table so the scrollbar is bigger
                  
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    width: '10px',
                    height: '10px',
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                    backgroundColor: 'darkgrey',
                    borderRadius: '10px',
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  },


                  // Animation that shakes the cell only once
                  // It gives a visual feedback that there is more to this table
                  '@media (max-width: 600px)': {
                    '& .MuiDataGrid-cell': {
                      animation: 'shakeCell 1s cubic-bezier(.36,.07,.19,.97) 1', // '1' for animation-iteration-count to run only once
                    },
                    '@keyframes shakeCell': {
                      '0%, 100%': {
                        transform: 'translateX(0)',
                      },
                      '10%, 30%, 50%, 70%, 90%': {
                        transform: 'translateX(3px)',
                      },
                      '20%, 40%, 60%, 80%': {
                        transform: 'translateX(-3px)', // Changed to negative translate for proper shaking effect
                      },
                    },
                  },


                  // CSS to style the status column
                  // It adds a colored bar to the left of the cell based on the status
                  '& .status-1': {
                    position: 'relative',
                    backgroundColor: '#dff0d8',
                    color: 'black',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: '5px',
                      backgroundColor: 'green',
                      boxSizing: 'border-box',
                    },
                  },
        
                }}

              />


          <Legend />
          {/* <Box sx={{ padding: 2, backgroundColor: "rgba(240, 240, 240, 0.8)", color: "black", borderRadius: "0px 0px 10px 10px" }}>
            <Typography variant="body2">
              Footer content here - maybe a summary, copyright info, or any data relevant to the grid above.
            </Typography>
          </Box> */}
  
            <div className='calendarComponent' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <h1>CALENDAR PROP</h1>  */}
            <CalendarComponent bookings={bookingsArray} style={{backgroundColor: "black"}} className='calendarItem' />
            {/* <p>{propertyName}</p> */}
            {/* <p>{bookingsArray}</p> */}
            {/* {bookingsArray.map((booking) => {
              console.log(booking)
             })} */}
          </div>
        </div>
    )})}
            
        </Box>
    );
}
